import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import { trackConversion } from '../utils/conversionTracker'
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios
import BusinessBlogs from "../Blogs/BusinessBlogs";
import { Carousel } from 'react-responsive-carousel'; // Import Carousel component
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel CSS

// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose} // Close modal when clicking outside content
    >
<div
  className="bg-white rounded-lg shadow-lg w-3/4 max-w-4xl p-6 relative"
  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside content
>

        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 absolute top-2 right-2 text-2xl font-bold"
          aria-label="Close Modal"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

const BuisessStartupVendors = () => {
  const [industryLeaders, setIndustryLeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);
  const [loading, setLoading] = useState(true);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    email: "",
    category: "",
    description: "",
  });

  // Submission state
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const gallery = [
    {
      id: 1,
      imgSrc: "/b1.jpg",
      catalog: "New Delhi",
      title: "Carnival By Pawasana",
      url: "https://in.bookmyshow.com/events/carnival-by-pawasana/ET00417460"
    },
    {
      id: 2,
      imgSrc: "/b2.jpg",
      catalog: "Delhi",
      title: "India Art Festival",
      url: "https://in.bookmyshow.com/events/india-art-festival/ET00408795"
    },
    {
      id: 3,
      imgSrc: "/b3.jpg",
      catalog: "Delhi",
      title: "The Tutelage - Bimal Das Gupta",
      url: "https://in.bookmyshow.com/events/the-tutelage-bimal-das-gupta/ET00416712"
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const response = await new Promise((resolve) =>
        setTimeout(() => {
          resolve([
            { id: 1, name: "Investment & Finance", photo: "invest.jpg", url: "/investment", contact: "Get Quote "  },
            { id: 2, name: "legal & Compliance", photo: "legal.jpg", url: "#", contact: "Get Quote " },
            { id: 3, name: "Strategy and Planning", photo: "strategy.jpg",url: "#", contact: "Get Quote " },
            { id: 4, name: "Consulting", photo: "consulting.jpg", url: "#", contact: "Get Quote " },
            { id: 5, name: "Accounting", photo: "accounting.webp", url: "#", contact: "Get Quote " },
            { id: 6, name: "Marketing(online/offline)", photo: "marketing.jpg", url: "/marketing", contact: "Get Quote "},
          ]);
        }, 50)
      );
      
      setIndustryLeaders(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (loadedImages === industryLeaders.length) {
      setIsLoading(false);
    }
  }, [loadedImages, industryLeaders.length]);

  useEffect(() => {
    
    window.scrollTo(0, 0);
    fetchData();
  }, [fetchData]);
  

  const handleImageLoad = () => {
    setLoadedImages((prev) => prev + 1);
  };

  const handleCardClick = (vendor) => {
    if (vendor.name === "Marketing(online/offline)") {
      navigate("/marketing"); // Programmatically navigate to the Marketing route
      return;
    }
  
    setSelectedVendor(vendor);
    setIsModalOpen(true);
    setIsSubmitted(false);
    setSubmissionError(null);
  
    // Set description field to selected vendor's name
    setFormData({
      phone: "",
      name: "",
      email: "",
      category: vendor.name, // Automatically set category
      description: vendor.name, // Automatically set description
    });
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsSubmitted(false);
    setSubmissionError(null);
    setFormData({
      phone: "",
      name: "",
      email: "",
      description: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    trackConversion();

    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionError(null);

    try {
      const apiEndpoint = "https://api.procydia.com/api/business/";

      const payload = {
        vendorId: selectedVendor.id,
        vendorName: selectedVendor.name,
        phone: formData.phone,
        name: formData.name,
        email: formData.email,
        category: formData.category,
        description: formData.description,
      };

      const response = await axios.post(apiEndpoint, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Form submitted successfully:", response.data);
      setIsSubmitted(true);

      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionError("Failed to submit the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bg-white text-black p-10 w-full mt-12">
      {isLoading && (
        <div className="flex justify-center items-center h-40">
          <p className="text-gray-600 font-bold">Loading...</p>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="text-center mb-8">
            <p className="text-gray-600 font-bold">Discover The Best Solution</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {industryLeaders.map((leader) => (
              <div
                key={leader.id}
                className="block overflow-hidden shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl rounded-lg cursor-pointer"
                onClick={() => handleCardClick(leader)}
              >
                <img
                  src={leader.photo}
                  alt={leader.name}
                  loading="lazy"
                  className="w-full h-32 md:h-40 object-cover rounded-t-lg"
                  onLoad={handleImageLoad}
                />
                <div className="p-2 bg-slate-100">
                  <h3 className="text-sm md:text-base font-bold text-black">
                    {leader.name}
                  </h3>
                  <p className="text-xs md:text-sm text-gray-600">
                    {leader.subName}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>

{/* Gallery Section */}
<div className="my-8 px-4">
<p className="text-gray-600 font-bold text-center">Previous Projects</p>
  <Carousel
    showThumbs={false}
    infiniteLoop
    autoPlay
    interval={3000}
    showStatus={false}
    dynamicHeight={false}
    centerMode
    centerSlidePercentage={60}
  >
    {gallery.map((gallery) => (
      <div key={gallery.id} className="flex flex-col gap-5 items-center">
        <img
          src={gallery.imgSrc}
          alt={gallery.name}
          className="w-36 h-96 object-cover rounded-xl p-6 shadow-md mb-2"
        />
      </div>
    ))}
  </Carousel>
</div>

      <div>
        <BusinessBlogs />
      </div>
      <Footer />

      {/* Modal for Form and Confirmation */}
      <Modal 
  isOpen={isModalOpen} 
  onClose={handleCloseModal}
  className="max-w-5xl mx-auto p-4"
>
  {!isSubmitted ? (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
      {/* Article Section (hidden on mobile, shown on medium and larger screens) */}
      <div className="md:w-1/2 p-6 bg-gray-100 rounded-md md:block hidden">
        <h2 className="text-lg font-bold mb-4">Why Contact Us?</h2>
        <p className="text-gray-700 mb-2">
          Get personalized assistance for {selectedVendor?.name}. Our team will
          help you with detailed insights and tailored solutions to meet your
          needs.
        </p>
        <ul className="list-disc ml-5 text-gray-700 mb-4">
          <li>Quick response time</li>
          <li>Expert advice</li>
          <li>Comprehensive support</li>
        </ul>
        <p className="text-gray-700 mb-2">
          At {selectedVendor?.name}, we believe in providing top-notch services
          to ensure customer satisfaction. Our dedicated team of professionals
          is always ready to assist you with:
        </p>
        <ul className="list-disc ml-5 text-gray-700 mb-4">
          <li>Customized solutions based on your requirements</li>
          <li>Up-to-date industry insights and trends</li>
          <li>Seamless communication throughout the process</li>
        </ul>
        <p className="text-gray-700">
          Contact us today to discover how we can help you achieve your goals
          efficiently and effectively. We're here to make your experience with
          {selectedVendor?.name} smooth and hassle-free.
        </p>
      </div>

      {/* Form Section */}
      <div className="md:w-1/2">
        <h2 className="text-xl font-bold mb-4 text-center">
          Contact Us For {selectedVendor?.name} 
        </h2>
        {submissionError && (
          <div className="mb-4 text-red-500">{submissionError}</div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              placeholder="Your Name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              placeholder="you@example.com"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              placeholder="123-456-7890"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              placeholder="Describe your requirements..."
            ></textarea>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition-colors ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
        {/* Skip Button */}
        <button
          onClick={handleCloseModal}
          className="mt-4 w-full bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition-colors"
        >
          Skip
        </button>
      </div>
    </div>
  ) : (
    <div className="text-center">
      <h2 className="text-xl font-bold mb-4">Submission Successful!</h2>
      <p className="mb-2">
        <strong>Name:</strong> {formData.name}
      </p>
      <p className="mb-2">
        <strong>Email:</strong> {formData.email}
      </p>
      <p className="mb-2">
        <strong>Contact Number:</strong> {formData.phone}
      </p>
      <p className="mb-4">
        <strong>Description:</strong> {formData.description}
      </p>
      <h2 className="text-xl font-bold mb-4">We will reach you 📞 shortly⌛</h2>
      <p className="text-gray-600">Redirecting to home page...</p>
    </div>
  )}
</Modal>




    </div>
  );
};

export default React.memo(BuisessStartupVendors);



