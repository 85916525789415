import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [filteredBlogs, setFilteredBlogs] = useState([]); // Filtered blogs state

  const navigate = useNavigate();

  // Fetch blogs from the backend API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("https://api.procydia.com/api/blog/blog");
        setBlogs(response.data);
        setFilteredBlogs(response.data); // Initialize filtered blogs with all blogs
      } catch (err) {
        setError("Failed to load blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter blogs dynamically
    const filtered = blogs.filter(
      (blog) =>
        blog.title.toLowerCase().includes(query) ||
        blog.category.toLowerCase().includes(query) ||
        blog.excerpt.toLowerCase().includes(query)
    );
    setFilteredBlogs(filtered);
  };

  // Navigate to the most appropriate blog (on Enter key press)
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && filteredBlogs.length > 0) {
      navigate(`/blogs/${filteredBlogs[0]._id}`); // Redirect to the top result
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <Navbar />
      <div className="mx-auto p-8 m-20">
      <h1 className="text-4xl font-bold mb-8 text-center">
          Read Our Blogs to Enhance Your Knowledge
        </h1>
        {/* Search Bar */}
        <div className="flex justify-center mb-8">
          <input
            type="text"
            placeholder="Search blogs by title, category, or keywords..."
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            className="w-full sm:w-2/3 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Blog List */}
        {filteredBlogs.length === 0 ? (
          <p className="text-center text-gray-500">No blogs found.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredBlogs.map((blog) => (
        <div
          key={blog.id}
          className="bg-white shadow-md hover:shadow-xl transition-shadow rounded-lg overflow-hidden"
        >
          <Link to={`/blogs/${blog._id}`} className="block group">
            <div className="p-4">
              <div className="flex flex-col sm:flex-row sm:space-x-4 items-center">
                <img
                  src={
                    blog.image
                      ? `https://api.procydia.com${blog.image}`
                      : "https://s39940.pcdn.co/wp-content/uploads/2023/01/iStock-1383963898.jpg"
                  }
                  alt={blog.title}
                  className="w-full sm:w-32 h-48 sm:h-32 object-cover group-hover:scale-105 transition-transform duration-300 rounded-lg"
                />
                <div className="flex flex-col mt-4 sm:mt-0">
                  <h2 className="text-base sm:text-lg font-bold text-gray-800 mb-1 sm:mb-2 group-hover:text-blue-600 transition-colors">
                    {blog.title}
                  </h2>
                  <p className="text-gray-600 text-sm">{blog.excerpt}</p>
                </div>
              </div>
              <p className="text-gray-500 text-xs mt-2 sm:mt-1">
                {new Date(blog.date).toLocaleDateString()}
              </p>
            </div>
          </Link>
        </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default BlogList;
