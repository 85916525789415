import React, { useEffect, useRef, useState } from 'react';
import Footer from '../Navbaar&Footer/Footer';
import Navbar from '../Navbaar&Footer/Navbar';


const LMapComponent = () => {
  const images = [
    { id: 1, src: "https://res.cloudinary.com/purnesh/image/upload/w_1080,f_auto/g-k-i-%2C-m-block00795.jpg", alt: "M Block Market Entrance", title: "The Grand Entrance" },
    { id: 2, src: "https://res.cloudinary.com/purnesh/image/upload/w_1080,f_auto/gk-ii-m-block-market-new-delhi.jpg", alt: "Clothing Shops", title: "Trendy Clothing Shops" },
    { id: 3, src: "https://gluttonyguilts.com/wp-content/uploads/2024/01/Tirupati-Vrindavan-M-Block-Market-Greater-Kailash-1-New-Delhi.jpg", alt: "Street Food", title: "Street Food Delights" },
    { id: 4, src: "https://b.zmtcdn.com/data/pictures/7/20930027/f0826a6c14a0de492b7eb8ca7f0cc95a_featured_v2.jpg", alt: "Artisan Stores", title: "Artisan Handicrafts" },
    { id: 5, src: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/534459505.jpg?k=8a18326d4bedd82f18aa388591a2d217f586bfcc1b36b3fabdc29f12bd79b182&o=&hp=1", alt: "Night View", title: "Beautiful Night View" },
  ];

  return (
    <>
   <Navbar />
    <div className="bg-white text-black py-10 px-6 mt-10">
      {/* Blog Header */}
      <div className="text-center mb-10">
        <h1 className="text-3xl font-bold mb-2">Exploring M Block Market 🛍️</h1>
        <p className="text-gray-600">
          A vibrant hub for fashion, food, and culture lovers. Here's a glimpse into the market life!
        </p>
      </div>

      {/* Image Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {images.map((image) => (
          <div
            key={image.id}
            className="overflow-hidden rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
          >
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-64 object-cover"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Blog Footer */}
      <div className="text-center mt-10">
        <p className="text-gray-700 font-medium">
          Visit M Block Market to experience the perfect blend of shopping and food!
        </p>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default LMapComponent;
