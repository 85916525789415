import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ConstructionBlogs = () => {
  const [constructionBlogs, setConstructionBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blogs from backend API
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("https://api.procydia.com/api/blog/blog");
        const filteredBlogs = response.data.filter((blog) => blog.category === "construction");
        setConstructionBlogs(filteredBlogs);
      } catch (err) {
        setError("Failed to load Construction blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="container mx-auto p-8 m-20 mt-2 mb-2">
        <h1 className="text-gray-600 font-bold mb-8 text-center">
          Read our blogs on Construction
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-5 p-2 sm:p-4">
          {constructionBlogs.map((blog) => (
            <div
              key={blog._id}
              className="bg-white shadow-md hover:shadow-xl transition-shadow rounded-lg overflow-hidden"
            >
              <Link to={`/blogs/${blog._id}`} className="block group">
                <div className="p-4">
                  <div className="flex flex-col sm:flex-row sm:space-x-4 items-center">
                    <img
                      src={
                        blog.image
                          ? `https://api.procydia.com${blog.image}`
                          : "https://s39940.pcdn.co/wp-content/uploads/2023/01/iStock-1383963898.jpg"
                      }
                      alt={blog.title}
                      className="w-full sm:w-32 h-48 sm:h-32 object-cover group-hover:scale-105 transition-transform duration-300 rounded-lg"
                    />
                    <div className="flex flex-col mt-4 sm:mt-0">
                      <h2 className="text-base sm:text-lg font-bold text-gray-800 mb-1 sm:mb-2 group-hover:text-blue-600 transition-colors">
                        {blog.title}
                      </h2>
                      <p className="text-gray-600 text-sm">{blog.excerpt}</p>
                    </div>
                  </div>
                  <p className="text-gray-500 text-xs mt-2 sm:mt-1">
                    {new Date(blog.date).toLocaleDateString()}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
        {/* View All Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/blogs" // Redirect to the main Blogs page
            className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-600"
          >
            View All Blogs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConstructionBlogs;
