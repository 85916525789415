import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandCards = () => {
  const brands = [
    { name: "Brand 1", logo: "brandlogo1.png" },
    { name: "Brand 2", logo: "brandlogo2.png" },
    { name: "Brand 3", logo: "brandlogo3.png" },
    // { name: "Brand 4", logo: "brandlogo4.jpg" },
    { name: "Brand 6", logo: "brandlogo5..png" },
    { name: "Brand 7", logo: "brandlogo6.webp" },
    { name: "Brand 8", logo: "brandlogo7.jpg" },
    { name: "Brand 9", logo: "brandlogo8.png" },
  ];

  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="mx-auto py-16 overflow-hidden">
      <h2 className="text-3xl font-bold text-center mb-8">Brands We've Worked With</h2>
      <Slider {...settings} className="w-full">
        {brands.map((brand, index) => (
          <div key={index} className="p-2">
            <div className="bg-white rounded-lg flex items-center justify-center transition-shadow">
              <img
                src={brand.logo}
                alt={brand.name}
                className="object-contain h-32"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandCards;
