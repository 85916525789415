import { FaCheckCircle } from 'react-icons/fa'; // Importing the icon library

const BrandPromo = () => {
  return (
    <div>
    <div className="p-8">
      <div
        className="relative bg-cover bg-center rounded-lg text-white bg-no-repeat shadow-lg"
        style={{
          backgroundImage: "url('new_b (3).png')",
        }}
      >
        {/* Dark overlay */}
        <div className="absolute rounded-lg"></div>

        <div className="container mx-auto py-16 relative z-10">
        <h2 className="text-4xl font-extrabold text-center text-white mb-12 px-4 sm:p-6">
  Procydia Assures
</h2>

<ul className="flex flex-wrap justify-center text-lg text-white gap-12 mb-12 px-4 sm:p-6">
  <li className="flex items-center mb-4">
    <FaCheckCircle className="text-green-500 text-2xl mr-3" />
    Convenient & Hassle-Free Execution
  </li>
  <li className="flex items-center mb-4">
    <FaCheckCircle className="text-green-500 text-2xl mr-3" />
    Efficient Resource Utilization
  </li>
  <li className="flex items-center mb-4">
    <FaCheckCircle className="text-green-500 text-2xl mr-3" />
    Deliver Best Customer Experience
  </li>
</ul>


          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-10 text-white text-center">
            <div className="p-8 rounded-lg text-white  transition-all">
              <h3 className="text-xl font-semibold  mb-4">Vendor Selection</h3>
              <p className="">We select the best vendors for your needs.</p>
            </div>
            <div className="p-8 rounded-lg text-white  transition-all">
              <h3 className="text-xl font-semibold  mb-4">Quality Checks</h3>
              <p className="">We ensure the highest standards are met.</p>
            </div>
            <div className="p-8 rounded-lg bg-inherit text-white transition-all">
              <h3 className="text-xl font-semibold  mb-4">Budget Optimization</h3>
              <p className="">We help you make the most of your budget.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default BrandPromo;
