import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";
import remarkGfm from 'remark-gfm';

const BlogPost = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [suggestedBlogs, setSuggestedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://api.procydia.com/api/blog/blog/${id}`);
        setBlog(response.data);
      } catch (err) {
        setError("Blog not found. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    const fetchSuggestedBlogs = async () => {
      try {
        const response = await axios.get("https://api.procydia.com/api/blog/blog/");
        
        // Randomly select 3 blogs from all data
        const randomBlogs = response.data
          .sort(() => 0.5 - Math.random())
          .slice(0, 3);
  
        setSuggestedBlogs(randomBlogs);
      } catch (err) {
        console.error("Error fetching suggested blogs:", err);
      }
    };

    fetchBlog();
    fetchSuggestedBlogs();
  }, [id]);

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
  if (!blog) return <div className="text-center py-10">Blog Not Found</div>;

  return (
<div>
  <Navbar />
  <div className="container mx-auto p-6 md:p-12 mt-10">
    {/* Back to Blogs Link */}
    <Link to="/blogs" className="text-blue-600 hover:underline mb-6 inline-block">
      &larr; Back to Blogs
    </Link>

    {/* Blog Layout with Flexbox */}
    <div className="flex flex-col lg:flex-row gap-8">
      {/* Blog Content (3/4 width) */}
      <div className="lg:w-3/4">
        {/* Blog Image */}
        <img
          src={blog.image ? `https://api.procydia.com${blog.image}` : "https://s39940.pcdn.co/wp-content/uploads/2023/01/iStock-1383963898.jpg"}
          alt={blog.title}
          className="w-full h-96 object-cover rounded-lg shadow-lg mb-8"
          onError={() => console.log("Image failed to load")}
        />

        {/* Blog Title and Content */}
        <h1 className="text-4xl font-bold mb-4 text-gray-900">{blog.title}</h1>
        <p className="text-gray-500 mb-8 text-lg">
          Published on: {new Date(blog.date).toLocaleDateString()}
        </p>
        <ReactMarkdown
          className="prose prose-lg text-justify leading-loose text-gray-700"
          remarkPlugins={[remarkGfm]}
        >
          {blog.content}
        </ReactMarkdown>

        <style jsx>{`
          /* Enhanced styles for readability */
          .prose p {
            margin-bottom: 1.5rem; /* Adds more spacing between paragraphs */
          }

          .prose a {
            color: #1d4ed8; /* Tailwind's blue-600 */
            text-decoration: underline;
          }

          .prose blockquote {
            border-left: 4px solid #10b981; /* Tailwind's green-400 */
            padding-left: 1rem;
            color: #1f2937; /* Tailwind's gray-800 */
            font-style: italic;
          }
        `}</style>

        {/* Feedback Button */}
        <div className="mt-10 text-center">
          <p className="text-lg text-gray-700">Do you like this edition?</p>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSfrz0Y35Lk3KOp1pO91TE2HLrYZIafhT_JWFcTvLa74QcLC2A/viewform?usp=preview"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full font-semibold text-lg hover:bg-blue-700 transition"
          >
            LEAVE A FEEDBACK
          </Link>
        </div>
      </div>

      {/* Sidebar Content (1/4 width) */}
      <div className="lg:w-1/4">
        {/* Suggested Blogs Section */}
        {suggestedBlogs.length > 0 && (
          <div className=" text-center">
            <span className="text-green-500 text-4xl">&#10004;</span>
            <a href='/'><h2 className="text-2xl font-semibold text-gray-900 text-center">Procydia</h2></a>
            <p>Proceed with your idea </p>
            <h2 className="text-2xl font-semibold text-gray-900 mb-6 text-center mt-8">Suggested Blogs</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-1">
              {suggestedBlogs.map((suggestedBlog) => (
                <Link
                  to={`/blogs/${suggestedBlog._id}`}
                  key={suggestedBlog._id}
                  className="border border-gray-300 rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
                >
                  {/* <img
                    src={suggestedBlog.image ? `https://api.procydia.com${suggestedBlog.image}` : "https://s39940.pcdn.co/wp-content/uploads/2023/01/iStock-1383963898.jpg"}
                    alt={suggestedBlog.title}
                    className="w-full h-48 object-cover rounded-t-lg"
                  /> */}
                  <div className="p-4">
                    <h3 className="font-bold text-lg text-gray-800 mb-2">{suggestedBlog.title}</h3>
                    <p className="text-gray-600 text-sm">
                      {new Date(suggestedBlog.date).toLocaleDateString()}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  <Footer />
</div>

  );
};

export default BlogPost;
