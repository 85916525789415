import React, { useEffect, useRef, lazy, Suspense } from "react";

// import News from "./News";


// Lazy load components
const Navbar = lazy(() => import("./Navbaar&Footer/Navbar"));
const Footer = lazy(() => import("./Navbaar&Footer/Footer"));


const Community = () => {
  useEffect(() => {
    // Set up the chatbot configuration
    window.embeddedChatbotConfig = {
      chatbotId: "Lssaom5UmC_taf9to7Bsk",
      domain: "www.chatbase.co",
    };
    // Dynamically load the chatbot script
    const script = document.createElement("script");
    script.src = "https://www.chatbase.co/embed.min.js";
    script.async = true;
    script.defer = true;
    script.setAttribute("chatbotId", "Lssaom5UmC_taf9to7Bsk");
    script.setAttribute("domain", "www.chatbase.co");

    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  const bannerRef = useRef(null);



  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Navbar />
      <Banner ref={bannerRef} />
      {/* <ExploreTopCategories />
      <BrandPromo /> */}
      {/* <Roadmap /> */}
      {/* <News /> */}
      {/* <ShopByDestinations />
      <WhatsHappening /> */}
      {/* <SocialMedia /> */}
      {/* <Blogs />
      <BrandCards /> */}
      <Footer />
    </Suspense>
  );
};

const Banner = React.forwardRef((props, ref) => (
    <>
<div
  ref={ref}
  className="bg-slate-400 text-white p-4 sm:p-6 md:p-10 flex flex-col md:flex-row items-center shadow-3xl w-full mt-11 relative"
  style={{
    backgroundImage: "url('community.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "67vh",
    minHeight: 350,
    maxHeight: 512,
  }}
>
    <div className="flex-2 p-4 md:p-8">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4  text-white">
      Connect and Grow: A Community for Every Passion
      </h2>
      <h2 className="text-xl sm:text-xl md:text-2xl font-semibold mb-4  text-white">
      Join like-minded individuals, share your interests, and build meaningful connections
      </h2>
      <h2 className="text-xl sm:text-xl md:text-2xl font-semibold mb-4  text-white">
       Powered by Procydia
      </h2>
    </div>
    <div className="flex-1 flex justify-center items-center relative mt-4 md:mt-0"></div>
  </div>
  <section className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto text-center">
        <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl">
          Join Our Business Community
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Joining a business community is crucial for entrepreneurs because it provides a wealth
          of benefits that can significantly enhance both personal and professional growth.
        </p>
      </div>
      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition"
          >
            <div className="text-indigo-600 font-semibold text-lg">{benefit.number}</div>
            <h3 className="mt-2 text-xl font-semibold text-gray-800">{benefit.title}</h3>
            <p className="mt-2 text-gray-600">{benefit.description}</p>
          </div>
          
        ))}

      </div>
    </section>
    <div className='bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 text-center'>
        <a href="/contact">
    <h1 className="text-xl font-bold text-gray-800 hover:text-blue-500">
          Be a Part of our Community Now!
        </h1>
        </a>
        </div>
  </>
));

const benefits = [
    { number: '1', title: 'Networking Opportunities', description: 'Build meaningful connections with like-minded entrepreneurs.' },
    { number: '2', title: 'Access to Knowledge and Resources', description: 'Tap into a vast pool of shared expertise and tools.' },
    { number: '3', title: 'Support System', description: 'Gain support and advice from a community of peers.' },
    { number: '4', title: 'Learning Opportunities', description: 'Continuously improve with workshops, webinars, and shared experiences.' },
    { number: '5', title: 'Increased Visibility', description: 'Expand your reach through community-led promotions and events.' },
    { number: '6', title: 'Accountability', description: 'Stay on track with your goals with community encouragement.' },
    { number: '7', title: 'Access to Funding and Resources', description: 'Unlock opportunities for financial support and partnerships.' },
    { number: '8', title: 'Shared Opportunities', description: 'Collaborate on exciting projects with trusted community members.' },
    { number: '9', title: 'Credibility and Validation', description: 'Boost your reputation by associating with a trusted network.' },
    { number: '10', title: 'Inspiration and Motivation', description: 'Stay inspired with stories and experiences from others.' },
  ];

export default Community;