import React, { useEffect } from 'react';

//google analytics
import ReactGA from 'react-ga4';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Pending from './components/Pending';
import ContactForm from './components/Social/Contact';
import LegalAgreements from './components/Legal/Legal Agreements';
import PrivacyPolicy from './components/Legal/Privacypolicy';
import TermsAndConditions from './components/Legal/Terms&condition';
import About from './components/About/About';
//home
import Home from './components/Home/Home';
//login and register page
import VendorOnboarding from './components/Register and Login/SignIn';
import LoginPage from './components/Register and Login/Login';
//wedding
import Catering from './components/Catering/Catering';
import Entertainment from './components/Entertainment/Entertainment';
import Crew from './components/Crew/Crew';
import WeddingCatalogue from './components/Wedding/Wedding';
import Location from './components/Wedding/Location';
import InvitesAndGifts from './components/Invitesandgifts/Gifts';
import Decoration from './components/Decoration/Decoration';
import WeddingVendors from './components/Wedding/WeddingVendors';
//gallery
import Gallery from './components/Gallery/Gallery';
//construction
import Construction from './components/Construction/Construction';
import ConstructionVendors from './components/Construction/ConstructionVendors';
//select by location
import Market from './components/Market/Market';
import MapComponent from './components/Market/RajouriMarket';
import CCMapComponent from './components/Market/ChandniChowkMarket';
import KMapComponent from './components/Market/KhanMaeket';
import SMapComponent from './components/Market/SarojiniMarket';
import LMapComponent from './components/Market/LajpatMarket';
import MMapComponent from './components/Market/MBlockMarket';
//career
import Careers from './components/Careers/Career';
//profile
import VendorDetails from './components/Profile/Profile';
import VendorDetailsed from './components/Profile/editprofile';
//Blogs
import BlogList from './components/Blogs/BlogList';
import BlogPost from './components/Blogs/BlogPost';
import ConstrutionBlogs from './components/Blogs/ConstrutionBlogs';
import BuisnessAndStartup from './components/Buisness/BuisnessAndStartup';
import EventBlogs from './components/Blogs/EventBlogs';
import HomeBlogs from './components/Blogs/HomeBlogs';
//Startup&Business
import Investment from './components/Investment/Investment';
import MarketList from './components/Market/MarketList';
import MarketPage from './components/Market/MarketPage';
import Marketing from './components/Marketing/Marketing';
import Community from './components/Community';



const TRACKING_ID = "G-F4ENXPXQ6Z"; // Replace with your actual Tracking ID
ReactGA.initialize(TRACKING_ID);


const App = () => {

  useEffect(() => {
    // Track the initial page load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/marketlist" element={<MarketList />} />
        <Route path="/market/:marketId" element={<MarketPage />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<VendorOnboarding />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profile/:vendorId" element={<VendorDetails />} /> 
        <Route path="/editprofile/:vendorId" element={<VendorDetailsed />} />
        <Route path="/wedding" element={<WeddingCatalogue />} />
        <Route path="/location" element={<Location/>} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/weddingvendors" element={<WeddingVendors />} />
        <Route path="/legal" element={<LegalAgreements />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms&condition" element={<TermsAndConditions />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/about" element={<About />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/entertainment" element={<Entertainment />} />
        <Route path="/crew" element={<Crew />} />
        <Route path="/gifts" element={<InvitesAndGifts />} />
        <Route path="/decoration" element={<Decoration />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/constructionvendors" element={<ConstructionVendors />} />
        <Route path="/rajourigardenmarket" element={<MapComponent />} />
        <Route path="/chandnichowkmkt" element={<CCMapComponent />} />
        <Route path="/khanmkt" element={<KMapComponent />} />
        <Route path="/sarojinimkt" element={<SMapComponent />} />
        <Route path="/lajpatmkt" element={<LMapComponent />} />
        <Route path="/mblockmkt" element={<MMapComponent />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/buisnessstartup" element={<BuisnessAndStartup />} />
        <Route path="/Market" element={<Market/>} />
        <Route path="/investment" element={<Investment/>} />
        {/* Route for blogs */}
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blogs/:id" element={<BlogPost />} />
        <Route path="/Construtionblogs" element={<ConstrutionBlogs />} />
        <Route path="/eventblogs" element={<EventBlogs />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/homeblogs" element={<HomeBlogs />} />
        <Route path="/communities" element={<Community />} />

      </Routes>
    </div>
  </Router>
  );
  // Optional: NotFound Component
// const NotFound = () => (
//   <div className="flex items-center justify-center h-full">
//     <h2 className="text-2xl font-bold">404 - Page Not Found</h2>
//   </div>
// );
};

export default App;
